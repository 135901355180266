.contractorForm {
  min-height: 98%;
  margin-top: 8px;
}

.formGridAlign {
  text-align: right !important;
}

.formSelectGridAlign {
  text-align: right !important;
  vertical-align: text-bottom !important;
}

.formInput {
  margin-bottom: 0.75rem !important;
  margin-top: 0.75rem !important;
  max-width: 32rem;
}

.formInputMaxWidth {
  max-width: 32rem;
}

.formMultipleInput {
  max-width: 32rem;
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.formButtonGroup {
  display: flex;
  flex-direction: row;
  text-align: center;
}

.formDiscardButton {
  margin-top: 0.75rem !important;
  width: 100%;
  margin-right: 0.25rem;
}

.formSaveButton {
  margin-top: 0.75rem !important;
  width: 100%;
  margin-left: 0.25rem;
}

.gridRemoveRowButton {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
  width: 100%;
  margin-right: 0rem;
  // margin-left: -0.25rem !important;
  // text-align: center !important;
}

.gridAddRowButton {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
  width: 100%;
  margin-left: 0.25rem;
}

input[type = time] {
  padding: 5px 25px 5px 25px;
}

// input[type = email] , input[type = number]{
//   padding: 5px 15px;
// }

// .contacts-table tr th{
//     padding: 0px 50px 0px 50px;
// }

@media screen and (max-width: 600px) {
  .formGridAlign {
    text-align: left !important;
  }
}

@media screen and (max-width: 720px) {
  .contractorForm {
    margin-top: 1px;
  }

  .formButtonGroup {
    flex-direction: column;
    text-align: center;
  }

  .formDiscardButton {
    margin-right: 0px;
  }

  .formSaveButton {
    margin-left: 0px;
  }
}



.contacts-table{
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  text-align: start;
  // justify-content: space-between;
  width: 75%;
}

.contacts-table tr{
  padding-left: 100px;
}

.contacts-table th {
  padding-left: 10px;
  // padding-right:10px;
  width: 100px;
  // height: 50px;
  text-align: center;
  width:200px;
  border: 1px solid black;
}

.tableDropDown{
  // align-items: center;
  justify-items: center;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 100% 60% 35% 70%;
}


.workorderdetails{
  width: -webkit-fill-available;
  .wrkName{
    width: 10%;
  }
  .wrkEmail{
    width: 15%;
  }
  .wrkPhone{
    width: 10%;
  }
  .wrkNote{
    width: 25%;
  }
  td {
    column-gap: 10px;
    text-align: center;
    // background-color: rgb(165, 165, 152);
  }
  th{
    // background-color: rgb(165, 165, 152);
    width:8%;
    text-align: center;
  }
}
.workorderdetailsbutton{
  width: max-content;
}
