.feedback-emoji {
  font-size: 30px;
  padding: 0.5rem;
}

.feedback-emojiImage {
  font-size: 30px;
  padding: 4px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #737373;
}

.feedback-satisfactionLevel {
  text-align: center;
}

.feedback-emojiButton {
  background-color: #ffffff;

  .feedback-satisfactionLevel {
    text-align: left;
  }
}

.radio-toolbar input[type='radio'] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar label {
  background-color: #ffffff;
  text-align: center;
  align-items: center;
}

.radio-toolbar label:hover {
  font-weight: bold;
  color: #f06c00;

  .feedback-emojiImage {
    padding: 4px;
    border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #f06c00;
  }
}

.radio-toolbar input[type='radio']:focus + label {
  font-weight: bold;
  color: #f06c00;

  .feedback-emojiImage {
    padding: 4px;
    border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #f06c00;
  }
}

.radio-toolbar input[type='radio']:checked + label {
  font-weight: bold;
  color: #f06c00;

  .feedback-emojiImage {
    padding: 4px;
    border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #f06c00;
  }
}

@media only screen and (max-width: 480px) {
  .feedback-emojiButtonName {
    display: inline;
    padding-left: 15px;
  }

  .feedback-satisfactionLevel {
    text-align: left;
  }
}