@import '@jsluna/style/scss/main';

.body {
  overflow-y: hidden;
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: gray;
}

.modal .ln-c-button-group {
  margin-bottom: 0px !important;
}