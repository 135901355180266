.multiple-input-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.multiple-inputs {
  display: flex;
  flex-wrap: wrap;
}

.input-tag-item {
  background-color: #D8D8D8;
  font-size: 16px;
  border-radius: 30px;
  padding: 4px 4px 4px 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
  height: fit-content;
}

.input-tag-item > .input-tag-item-button {
  background-color: transparent;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  margin-left: 2px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-tag-item-button-icon {
  height: 18px;
  width: 18px;
}

.multiple-text-input-box {
  margin-bottom: 0px !important;
}