.textAlignLast_Center{
    text-align-last: center;
}

.justifyItems{
    justify-items: center;
}

.dropDownAlign{
    margin-top: 12px;
    margin-bottom: -12px;
    text-align-last: start;
}

.rowTextAlign{
    margin-top: 1.5rem;
}

.buttonTopAlign{
    margin-top: 12px;
}

.dateAndTime{
    display: flex;
    justify-content: center;
    margin: -10px;
    margin-bottom: 10px;
    @media (max-width: 460px) {
        margin-bottom: 10px;
        margin-top: -20px;
    }
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: start;
    }
    
}

.weekendHeadings{
    @media (max-width: 480px) {
        display: none;
    }
}

    @media (max-width: 1200px) and (min-width: 960px) {
        .xxx {
            padding: 0px 0px !important;
        }
        
    }
    @media (max-width: 1200px){
        .weekendContents{
            padding: 10px 20px 10px 10px;
            margin-bottom: 10px;
            margin-left: -10px;
            width: 110%;
            background-color: #f2f2f2;
        }
    }
.weekendContents{
    @media (max-width: 680px) {
        padding: 10px 20px 10px 10px;
        margin-bottom: 10px;
        margin-left: -10px;
       
        width: 110%;
        background-color: #f2f2f2;
        .dropDownAlign{
            text-align-last: start;
            width: 100%;
            padding: 0px 10px;
        }
        .textAlignLast_Center{
            text-align-last: left;
        }
        .rowTextAlign{
            margin-left: 10px;
        }
        .formButtonGroup{
            display: flex;
            flex-direction: row;
        }
    }
    @media (min-width: 480px) {
        label{
            display: none;
        }
    }
    @media (max-width: 480px) {
        .wknd_name{
            margin-left: -5%;
        }
        .wknd_level{
            margin-left: -3%;
            margin-top: -2%;
        }
        .wknd_phone{
            margin-left: 0%;
            margin-top: 1%;
        }
        .wknd_email{
            margin-left: 0%;
            margin-top: -5%;
        }
        .wknd_notes{
            margin-left: 0%;
            margin-top: -2%;
        }
    }
}

.site_care_on_call{
    margin-left: 2%;
}

.scm_Weekend_on_call{
    @media (min-width: 480px) {
        label{
            display: none;
        }
    }
    @media (max-width: 480px) {
        // padding: 10px 20px 10px 10px;
        margin-bottom: 5%;
        margin-left: 2%;
        margin-right: -2%;
        padding-right: 2%;
        padding-top: 5%;
        padding-bottom: 5%;
        background-color: #f2f2f2;
        .scm_detail{
            margin-bottom: -5%;
        }
        .scm_name{
            margin-bottom: -5%;
        }
    }
}
.scm_Weekend_on_call_headings{
    @media (max-width: 480px) {
        display: none;
    }
}
.popover-dialog {
    position: absolute;
    bottom: calc(100% - 10px); /* Places it above the input box */
    left: 50%;
    transform: translateX(-50%);
    background-color: #f0f0f0;
    padding: 10px;
    width: 300px; /* Adjust width as needed */
    min-height: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 1px solid #ddd;
    z-index: 1; /* Ensures it appears above other elements */
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    text-align: left;
    overflow-y: auto;
    line-height: 1.5;
    max-width: 250px;
    margin-bottom: 5px; /* Adds spacing so it doesn’t touch the input box */
    
 
}
 
.email{
    min-width: 270px; /* Adjust width as needed */
     /* Prevent breaking words incorrectly */
     white-space:nowrap;
     overflow: hidden;
     text-overflow:ellipsis;
    //  word-break: break-all; /* Use this if break-word is not effective */
    //  hyphens: auto; /* Adds hyphenation for better breaks */
    // //  justify-content:left!important;
    //  text-align:left;
     
    
}



