.page {
  padding-top: 0px !important;
}

.ln-o-page__body {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.container {
  margin-top: 0.5rem;
  height: calc(100% - 0.5rem);
}

.main-content {
  padding-bottom: 10rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  flex: 1;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

@media screen and (max-width: 720px) {
  .content {
    position: absolute;
    top: 60px;    
    height: calc(100% - 60px);
    padding: 0px !important;
  }
}