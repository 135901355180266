.contractors{
    @media (max-width: 860px) and (min-width: 600px) {
        margin-left: -6rem;
    }
}

.textAlignCenter{
    text-align: center;
}

.officeHours{
    display: grid;
    grid-template-columns: 25% 40% 40%;
    justify-items: center;
    align-items: center;
    // .hideSpan{
    //     @media (max-width: 400px) {
    //     // display: none;
        
    //     }
    // }
    .switchClass{
        display: flex;
        flex-direction: row;
        align-items: center;
        .switchCheckBox{
            margin-right: -10px;
            @media (max-width: 400px) {
                margin-right: -20px;
                margin-left: -10px;
            }
        }
    }
}
#officeHours{
    margin-top: 2rem;
}

.ofc_start{
    align-items: baseline;
    margin-bottom: 5%;
}

.ofc_end{
    align-items: baseline;
}

.accessToVarisae{
    display: grid;
    grid-template-columns: 27% 40%;
    justify-items: center;
    align-items: center;
    margin-top: 3rem;
    .switchClass{
        display: flex;
        flex-direction: row;
        align-items: center;
        .switchCheckBox{
            margin-right: -20px;
            @media (max-width: 330px) {
                margin-left: -5px;
                margin-right: -25px;
            }
            
        }
        // @media (max-width: 400px){
        //     .hideSwitch{
        //         display: none;
        //     }
        // }
    }
    
}
.dispatchMethod{
    display: grid;
    grid-template-columns: 30% 80%;
    justify-items: center;
    align-items: center;
    justify-content: space-around;
    .dispatch{
        display: grid;
        grid-template-columns: 30% 80%;
        .dispatchCheckBox{
            margin-top: 20px;
            li{
                list-style-type: none;
                @media (max-width: 700px) {
                    span{
                        margin-right: -20px;
                        margin-left: -10px;
                    }
                }
            }
            @media (max-width: 930px) {
                width: max-content;
            }
        }
        @media (max-width: 930px) {
            display: grid;
            grid-template-columns: none;
            grid-template-rows: 5% 10% !important;
            margin-bottom: -20%;
        }
        @media (max-width: 375){
            margin-top: -25%;
        }
    }
    @media (max-width: 700px) {
        display: grid;
        grid-template-columns: 25% 75%;
    }
    // @media (max-width: 550px) {
    //     display: grid;
    //     grid-template-rows: 5% 15%;
    //     grid-template-columns: none;
    //     align-items: flex-start;
    // }
}

.notes{
    display: grid;
    grid-template-columns:25% 95%;
    justify-content: space-around;
    .textNotes{
        width: 100%;
        height: 25%;
    }
    @media (max-width: 960px) {
        grid-template-columns: 25% 80%;
    }
    @media (max-width: 800px) {
        grid-template-columns: 25% 77%;
    }
}

.contactsGridItem{
    // margin-left: 6rem;
    @media (min-width: 600px) {
        margin-left: 6rem;
    }
    @media (max-width: 960px) {
        .xContacts{
            padding: 10px 20px 10px 10px;
            margin-bottom: 10px;
            background-color: #f2f2f2;
            .alignEmailPhn {
                    margin-top: 2%;
            }
        }
    }
    .contactsName{
        margin-top: -3%;
        @media (max-width: 960px) {
            margin-top: 2%;
        }
        @media (max-width: 500px) {
            margin-bottom: 0px;
        }
    }
}


.contacts_Contracts{
    @media (max-width: 860px) and (min-width: 600px) {
        margin-left: 5rem;
    }
}

.contractorContents{
    @media (max-width: 1200px){
        padding: 10px 20px 10px 10px;
        margin-bottom: 10px;
        margin-left: 2%;
        width: 100%;
        background-color: #f2f2f2;
    }
    @media (max-width: 1200px) {
        .left_contact_text {
            width: max-content;
        }
    }
    @media (max-width: 860px) and (min-width: 600px) {
        margin-left: 6rem;
        width: 90%;
    }
    @media (max-width: 600px) {
        padding: 10px 20px 10px 10px;
        margin-bottom: 10px;
        background-color: #f2f2f2;
        .dropDownAlign{
            text-align-last: start;
            width: 100%;
            padding: 0px 10px;
        }
        .textAlignLast_Center{
            text-align-last: left;
        }
        .rowTextAlign{
            margin-left: 10px;
            margin-top: 0%;
        }
        .formButtonGroup{
            display: flex;
            flex-direction: row;
        }
    }
    @media (min-width: 480px) {
        label{
            display: none;
        }
    }
}


.rowTextAlign { white-space: nowrap; 
    overflow: hidden; 
    &:hover{
        overflow: auto;
        padding-bottom: 2px;
    }
    &::-webkit-scrollbar { 
        margin-top: 2px;
        height: 2px;
    }
    &::-webkit-scrollbar-thumb { 
        background-color: rgb(105, 105, 105);
        border-radius: 2px;
    }
 }